.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}

@font-face {
  font-family: "Amatic Bold";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/amatic-bold.ttf"); /* IE9 Compat Modes */
  src: local("Amatic Bold"), local("AmaticBold");
}

.homeBackground {
  text-align: center;
  background-color: #d7f0b9;
  background-image: url(../images/aboutUs/community.jpg);
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  background-size: 100vw;
  display: flex;
  overflow: "hidden";
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 2;
  cursor: pointer;
}
